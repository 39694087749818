import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _82ad51d2 = () => interopDefault(import('../pages/config.ts' /* webpackChunkName: "pages/config" */))
const _aab14874 = () => interopDefault(import('../pages/daily_report.vue' /* webpackChunkName: "pages/daily_report" */))
const _57ef485c = () => interopDefault(import('../pages/driver_schedule.vue' /* webpackChunkName: "pages/driver_schedule" */))
const _60576031 = () => interopDefault(import('../pages/forget_password.vue' /* webpackChunkName: "pages/forget_password" */))
const _efdc7826 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _61ec7092 = () => interopDefault(import('../pages/reset_password.vue' /* webpackChunkName: "pages/reset_password" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/config/",
    component: _82ad51d2,
    pathToRegexpOptions: {"strict":true},
    name: "config"
  }, {
    path: "/daily_report/",
    component: _aab14874,
    pathToRegexpOptions: {"strict":true},
    name: "daily_report"
  }, {
    path: "/driver_schedule/",
    component: _57ef485c,
    pathToRegexpOptions: {"strict":true},
    name: "driver_schedule"
  }, {
    path: "/forget_password/",
    component: _60576031,
    pathToRegexpOptions: {"strict":true},
    name: "forget_password"
  }, {
    path: "/login/",
    component: _efdc7826,
    pathToRegexpOptions: {"strict":true},
    name: "login"
  }, {
    path: "/reset_password/",
    component: _61ec7092,
    pathToRegexpOptions: {"strict":true},
    name: "reset_password"
  }, {
    path: "/",
    redirect: "/daily_report/",
    name: "index"
  }, {
    path: "*",
    redirect: "/daily_report/",
    name: "notFound"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
